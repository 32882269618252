import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function wordLengthValidator(maxLength = 12): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		const words = control.value.split(/[\s-]+/);

		const invalidWords = words.filter((word) => word.length > maxLength);

		return invalidWords.length > 0 ? { wordTooLong: { maxLength, invalidWords } } : null;
	};
}
