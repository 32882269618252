import { Injectable } from '@angular/core';
import { configure } from '@monterosa-sdk/core';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
@Injectable({
	providedIn: 'root'
})
export class MonterosaService {
	private projectId: string;
	private token: string | null = null;

	constructor(private readonly config: ConfigService) {
		this.projectId = this.config.getSettings('monterosa.projectId', '');
		configure({
			host: 'cdn.monterosa.cloud',
			projectId: this.projectId
		});
	}

	setToken(token: string): void {
		if (!token || this.token === token) {
			return;
		}
		this.token = token;
	}

	getToken(): string | null {
		return this.token;
	}
}
