<div class="playerranking-card" [ngClass]="{ 'horizontal': horizontal, 'playerrankingLiveticker': isLiveticker }">
	<span class="title" *ngIf="!horizontal">{{ title }}</span>
	<div class="columns">
		<div class="column home" [ngStyle]="{ 'backgroundColor': homeColor, 'height': !horizontal ? homeSize : '100%', 'width': horizontal ? homeSize : '100%' }" [ngClass]="{ 'surround-with-border': homeBorderNeeded }">
			<span class="value" [ngStyle]="{ 'color': homeTextColor }" [ngClass]="{ 'white': homeBorderNeeded }">{{ ranking?.home?.value }}</span>
		</div>
		<div class="column away" [ngStyle]="{ 'backgroundColor': awayColor, 'height': !horizontal ? awaySize : '100%', 'width': horizontal ? awaySize : '100%' }" [ngClass]="{ 'surround-with-border': awayBorderNeeded }">
			<span class="value" [ngStyle]="{ 'color': awayTextColor }" [ngClass]="{ 'white': awayBorderNeeded }">{{ ranking?.away?.value }}</span>
		</div>
	</div>
	<div class="playerNames">
		<span class="home">
			{{ ranking?.home?.playerName | livetickerEventPlayername: 'first' }}
			<span class="surName">{{ ranking?.home?.playerName | livetickerEventPlayername: 'last' }}</span>
		</span>
		<span class="away">
			{{ ranking?.away?.playerName | livetickerEventPlayername: 'first' }}
			<span class="surName">{{ ranking?.away?.playerName | livetickerEventPlayername: 'last' }}</span>
		</span>
	</div>
	<div class="playerImage home" [ngClass]="{ 'linked': (enablePlayerLinking && ranking?.home?.playerSlug) || ranking?.home?.dflDatalibraryObjectId }" [ngStyle]="{ 'background-image': 'url(' + (ranking?.home?.playerImageUrl | imageService: 184) + ')' }" [routerLink]="getStatsPlayerCardLink(ranking?.home)"></div>
	<div class="playerImage away" [ngClass]="{ 'linked': (enablePlayerLinking && ranking?.away?.playerSlug) || ranking?.away?.dflDatalibraryObjectId }" [ngStyle]="{ 'background-image': 'url(' + (ranking?.away?.playerImageUrl | imageService: 184) + ')' }" [routerLink]="getStatsPlayerCardLink(ranking?.away)"></div>
</div>
