import { Pipe, PipeTransform } from '@angular/core';

type PlayerImageSizes = 'PORTRAIT' | 'FACE_CIRCLE' | 'DEPR_FACE_CIRCLE';

@Pipe({
	name: 'livetickerEventPlayerimage'
})
export class PlayerimagePipe implements PipeTransform {
	transform(value: string, size: PlayerImageSizes = 'PORTRAIT'): any {
		const sizeExtension = {
			PORTRAIT: '.png',
			FACE_CIRCLE: '-circle.png',
			DEPR_FACE_CIRCLE: '-circle-192x192.png'
		};
		// return original value if preconditions are not met
		if (!size || !Object.keys(sizeExtension).includes(size) || !value) {
			return value;
		}
		let extension = sizeExtension[size];
		// use deprecated circle image for older seasons
		if (size === 'FACE_CIRCLE' && (value.includes('-dfl-sea-0001k3') || value.includes('-dfl-sea-0001k2'))) {
			extension = sizeExtension['DEPR_FACE_CIRCLE'];
		}
		// remove any present affixes and clean inputvalue
		const re = new RegExp(Object.values(sizeExtension).join('|'), 'gi');
		value = value.replace(re, '.png');
		// return playerImage
		return value.replace('.png', extension);
	}
}
