<ng-container>
	@if (errors$ | async; as errors) {
		@for (error of errors | keyvalue; track $index) {
			<small class="form-errors-display__line">
				@switch (error.key) {
					@case ('required') {
						<mat-error>Input required</mat-error>
					}
					@case ('maxlength') {
						<mat-error>{{ error.value.actualLength }}/{{ error.value.requiredLength }} of characters exceeded</mat-error>
					}
					@case ('wordTooLong') {
						<mat-error
							>Words too long (max {{ error.value.maxLength }} chars):
							@for (word of error.value.invalidWords; track $index) {
								{{ word }}
							}
						</mat-error>
					}
					@default {
						<mat-error>Input error: {{ error.key }}</mat-error>
					}
				}
			</small>
		}
	}
</ng-container>
