import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BundesmasterStoriesApiService, OpenSearchStorySlides, SLIDE_TYPES, StoryTypes } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterUiDialogConfirmComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-confirm';
import { BundesmasterUiFormErrorsDisplayComponent } from '@nx-bundesliga/bundesmaster/ui/form-errors-display';

@Pipe({
	standalone: true,
	name: 'asFormArray'
})
export class AsFormArrayPipe implements PipeTransform {
	transform(value: AbstractControl): FormArray {
		return value as FormArray;
	}
}

@Component({
	selector: 'bundesmaster-dialog-slide-edit',
	standalone: true,
	imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule, FormsModule, MatDialogModule, MatButtonModule, MatIconModule, MatInputModule, MatOptionModule, MatSelectModule, AsFormArrayPipe, MatCheckboxModule, BundesmasterUiDialogConfirmComponent, BundesmasterUiFormErrorsDisplayComponent],
	templateUrl: './dialog-slide-edit.component.html',
	styleUrls: ['./dialog-slide-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogSlideEditComponent {
	public form: FormGroup;
	public slideType: SLIDE_TYPES;
	public slideTypes = SLIDE_TYPES;
	public storyType: StoryTypes;

	constructor(
		public dialogRef: MatDialogRef<DialogSlideEditComponent>,
		private readonly storiesApiService: BundesmasterStoriesApiService,
		@Inject(MAT_DIALOG_DATA) public data: { slide: OpenSearchStorySlides; storyType: StoryTypes }
	) {
		console.log('DialogSlideEditComponent', data);
		const slide = data.slide;
		this.storyType = data.storyType;
		this.slideType = slide.slideType;
		this.form = this.storiesApiService.createSlideFormGroup(slide, this.storyType);
		// remove validators from image property as it will hinder saving within the modal
		if (this.form.controls?.['image']) {
			this.form.controls?.['image']?.['controls']?.['url']?.clearValidators();
			this.form.controls?.['image']?.['controls']?.['copyright']?.clearValidators();
			this.form.controls?.['image']?.updateValueAndValidity();
		}
	}

	save() {
		this.dialogRef.close(this.form.value);
	}

	discard() {
		this.dialogRef.close(false);
	}
}
