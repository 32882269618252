import { Pipe, PipeTransform } from '@angular/core';
import { LiveBlogEntryEditorialEmbed, LiveBlogEntryEditorialEmbedMonterosa } from '@nx-bundesliga/models';

@Pipe({
	name: 'enableMonterosa',
	pure: true
})
export class EnableMonterosaPipe implements PipeTransform {
	transform(liveEntry: any): LiveBlogEntryEditorialEmbed {
		if (this.isEmbedEvent(liveEntry) && (liveEntry as LiveBlogEntryEditorialEmbedMonterosa)?.detail?.embedPlatform === 'monterosa') {
			return {
				...liveEntry,
				detail: {
					...liveEntry.detail,
					channelsEnabled: { web: true, mobile: true }
				}
			};
		}
		return liveEntry;
	}

	private isEmbedEvent(entry: any): boolean {
		// Implement the same isEmbedEvent logic from your component
		return entry?.entryType === 'embed';
	}
}
