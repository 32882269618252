import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getClubs } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { getWorkingClubs, getWorkingClubStateLoaded, getWorkingClubStateLoading } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { BundesligaRestService } from '@nx-bundesliga/bundesliga-com/services/common';
import { BUNDESLIGA_SEASONS, Club, ClubState, ClubStateData, Language } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { combineLatest, MonoTypeOperatorFunction, Observable, of, skipUntil, tap, throwError } from 'rxjs';
import { filter, map, share, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClubService extends BundesligaRestService {
	// A bug in angular requires us to overwrite ngInjectableDef in all child services, because this sevice inherits ngInjectableDef from
	// BundesligaRestService and therefore Angular can't construct the child service. See:
	// - https://github.com/angular/angular/pull/25736
	// - https://stackoverflow.com/questions/50263722/angular-6-services-and-class-inheritance
	// static ngInjectableDef = undefined;
	constructor(
		private http: HttpClient,
		private config: ConfigService,
		private readonly langStore: Store<Language>,
		private readonly clubStore: Store<ClubState>
	) {
		super(http, config, langStore);
	}

	private filterAndDespatchClubs(season: string): MonoTypeOperatorFunction<ClubStateData> {
		return skipUntil(
			combineLatest([this.clubStore.pipe(select(getWorkingClubStateLoaded(season))), this.clubStore.pipe(select(getWorkingClubStateLoading(season)))]).pipe(
				tap(([loaded, loading]) => {
					if (loaded === false && loading === false) {
						this.clubStore.dispatch(getClubs(season));
					}
				}),
				filter(([loaded, loading]) => loaded === true)
			)
		);
	}

	public transformClubArrayToObject(clubData: Club[]): { [key: string]: Club } {
		return clubData.reduce(
			(acc, item) => {
				acc[item.id] = item;
				return acc;
			},
			{} as { [key: string]: Club }
		);
	}

	/**
	 *
	 */
	public getAllClubsBySeason(sort: 'editorialorder' | 'alphabetical' = 'alphabetical', season: string = BUNDESLIGA_SEASONS.CURRENT): Observable<ClubStateData> {
		return this.clubStore.pipe(select(getWorkingClubs(season)), this.filterAndDespatchClubs(season));
	}

	/**
	 *
	 */
	public getAllClubsByCompetitionAndSeason(competition: string, sort: 'editorialorder' | 'alphabetical' = 'editorialorder', season: string = BUNDESLIGA_SEASONS.CURRENT.toString()): Observable<Club[]> {
		return this.clubStore.pipe(
			select(getWorkingClubs(season)),
			this.filterAndDespatchClubs(season),
			map((clubStateData: ClubStateData) => clubStateData[competition]),
			map((clubs: Club[]) => (sort === 'editorialorder' ? clubs : clubs.sort((a, b) => a.name.full.localeCompare(b.name.full))))
		);
	}

	/**
	 * @public validates a STS clubId
	 */
	public isValidClubId(club: string): boolean {
		if (!club) {
			return false;
		}
		return /^DFL-CLU-[A-Z0-9]{6}$/.test(club);
	}

	/**
	 * @private THIS METHOD SHOULD NEVER BE CALLED EXTERNALLY! IT HAS TO BE PUBLIC IN ORDER FOR THE EFFECT TO CALL IT.
	 */
	public getAllClubs(season: string = BUNDESLIGA_SEASONS.CURRENT): Observable<ClubStateData> {
		this.settings = this.config.getSettings('endpoints.club', 'club');
		const params = new HttpParams().set('sort', 'editorialorder').set('seasonId', season);
		return this.get<ClubStateData>('', false, { params }).pipe(
			map((clubStateData: ClubStateData) => ({
				'bundesliga': clubStateData['bundesliga'].map((club) => ({ ...club, competition: 'bundesliga' })),
				'2bundesliga': clubStateData['2bundesliga'].map((club) => ({ ...club, competition: '2bundesliga' }))
			}))
		);
	}

	public getNonBundesligaClubsBySeason(competitionId: string, season: string = BUNDESLIGA_SEASONS.CURRENT): Observable<Club[]> {
		this.settings = this.config.getSettings('endpoints.club', 'club');
		const paramsCompetitionId = competitionId === 'DFL-COM-J0002E' ? 'euro_championship' : 'bundesliga';
		const params = new HttpParams().set('seasonId', season).set('competition', paramsCompetitionId);
		return this.get<Club[]>('', false, { params });
	}

	/**
	 * @param {string} slugifiedFull the club's name
	 * @param season
	 */
	public getClub(slugifiedFull: string, season: string = BUNDESLIGA_SEASONS.CURRENT): Observable<Club> {
		return this.clubStore.pipe(
			select(getWorkingClubs(season)),
			this.filterAndDespatchClubs(season),
			share(),
			filter((data: ClubStateData) => {
				return data['bundesliga'].length > 0 && data['2bundesliga'].length > 0;
			}),
			map((clubStateData: ClubStateData) => [...clubStateData['bundesliga'], ...clubStateData['2bundesliga']]),
			// filter by name
			map((clubs: Club[]) => clubs.filter((club: Club) => club.name.slugifiedFull === slugifiedFull)),
			// hopefully only one correct item is left now which can be returned
			switchMap((clubs: Club[]) => {
				if (clubs.length > 0) {
					return of(clubs[0]);
				} else {
					return throwError('club not found');
				}
			})
		);
	}

	/**
	 * id {string} the DFL Datalibrary ID
	 */
	public getClubById(id: string, season: string = BUNDESLIGA_SEASONS.CURRENT): Observable<Club> {
		return this.clubStore.pipe(
			select(getWorkingClubs(season)),
			this.filterAndDespatchClubs(season),
			share(),
			map((clubStateData: ClubStateData) => [...clubStateData['bundesliga'], ...clubStateData['2bundesliga']]),
			// filter by threeLetterCode
			map((clubs: Club[]) => clubs.filter((club: Club) => club.id === id)),
			// hopefully only one correct item is left now which can be returned
			switchMap((clubs: Club[]) => {
				if (clubs.length > 0) {
					return of(clubs[0]);
				} else {
					return throwError('club not found');
				}
			})
		);
	}

	/**
	 *
	 * @param {string} slugifiedFull the club's name
	 * @return {string} the current competition of the given club, either bundesliga or 2bundesliga
	 */
	public getCompetitionByClub(slugifiedFull: string): Observable<string> {
		return this.getCompetition((club) => club.name.slugifiedFull === slugifiedFull);
	}

	/**
	 *
	 * @param {string} id the club's name
	 * @return {string} the current competition of the given club, either bundesliga or 2bundesliga
	 */
	public getCompetitionByClubId(id: string): Observable<string> {
		return this.getCompetition((club) => club.externalClubIds.dflDatalibraryClubId === id);
	}

	/**
	 *
	 * @param {(club: Club) => boolean} criteria a function that defines the search criteria
	 * @param season
	 * @return {string} the competition of the club that matched the criteria
	 */
	public getCompetition(criteria: (club: Club) => boolean, season: string = BUNDESLIGA_SEASONS.CURRENT): Observable<string> {
		return this.clubStore.pipe(
			select(getWorkingClubs(season)),
			this.filterAndDespatchClubs(season),
			map((data: ClubStateData) => {
				for (const competition of ['bundesliga', '2bundesliga']) {
					for (const club of data[competition]) {
						if (criteria(club)) {
							return competition;
						}
					}
				}
				throw new Error('club not found');
			})
		);
	}

	/**
	 *
	 */
	public getClubByTLC(tlc: string, season: string = BUNDESLIGA_SEASONS.CURRENT): Observable<Club> {
		return this.clubStore.pipe(
			select(getWorkingClubs(season)),
			this.filterAndDespatchClubs(season),
			map((clubStateData: ClubStateData) => [...clubStateData['bundesliga'], ...clubStateData['2bundesliga']]),
			// filter by threeLetterCode
			map((clubs: Club[]) => clubs.filter((club: Club) => club.threeLetterCode === tlc)),
			// hopefully only one correct item is left now which can be returned
			switchMap((clubs: Club[]) => {
				if (clubs.length > 0) {
					return of(clubs[0]);
				} else {
					return throwError('club not found');
				}
			})
		);
	}

	public getClubByFullSlug(slugifiedFull: string, season: string = BUNDESLIGA_SEASONS.CURRENT): Observable<Club> {
		return this.clubStore.pipe(
			select(getWorkingClubs(season)),
			this.filterAndDespatchClubs(season),
			map((clubStateData: ClubStateData) => [...clubStateData['bundesliga'], ...clubStateData['2bundesliga']]),
			// filter by threeLetterCode
			map((clubs: Club[]) => clubs.filter((club: Club) => club.name.slugifiedFull === slugifiedFull)),
			// hopefully only one correct item is left now which can be returned
			switchMap((clubs: Club[]) => {
				if (clubs.length > 0) {
					return of(clubs[0]);
				} else {
					return throwError('club not found');
				}
			})
		);
	}

	public getClubRouterURL(language: string, competition: string, clubSlug: string, tab?: string): string[] {
		if (!language || language === '' || !competition || competition === '') {
			return [];
		}
		let clubLink = ['/', language, competition, 'route-clubs'];
		if (clubSlug && clubSlug !== '') {
			clubLink = [...clubLink, clubSlug];
		}
		if (tab && tab !== '') {
			clubLink = [...clubLink, tab];
		}
		return clubLink;
	}

	public getWebviewClubRouterURL(language: string, clubId: string): string[] {
		if (!language || language === '' || !clubId || clubId === '') {
			return [];
		}
		return ['/', language, 'clubs', clubId];
	}
}
