import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnableMonterosaPipe } from './enable-monterosa.pipe';
import { ConferenceLivetickerPipe, LivetickerPipe } from './liveticker.pipe';
import { PlayerimagePipe } from './playerimage.pipe';
import { PlayernamePipe } from './playername.pipe';
import { VideoDurationPipe } from './videoduration.pipe';

@NgModule({
	declarations: [LivetickerPipe, ConferenceLivetickerPipe, PlayernamePipe, PlayerimagePipe, VideoDurationPipe, EnableMonterosaPipe],
	exports: [LivetickerPipe, ConferenceLivetickerPipe, PlayernamePipe, PlayerimagePipe, VideoDurationPipe, EnableMonterosaPipe],
	providers: [LivetickerPipe, ConferenceLivetickerPipe, PlayernamePipe, PlayerimagePipe, VideoDurationPipe, EnableMonterosaPipe],
	imports: [CommonModule]
})
export class LivetickerEventsPipeModule {}
