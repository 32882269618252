import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'livetickerEventPlayername'
})
export class PlayernamePipe implements PipeTransform {
	transform(value: string, part: 'full' | 'first' | 'last' = 'full'): any {
		if (part === 'full') {
			return this.getPlayerName(value);
		}
		if (part === 'first') {
			return this.getPlayerAliasFirstName(value);
		}
		if (part === 'last') {
			return this.getPlayerAliasLastName(value);
		}
		return value;
	}

	public getPlayerName(name: string): string {
		return name;
	}

	public getPlayerAliasFirstName(name: string): string {
		const indexOf = name.indexOf(' ');
		if (indexOf === -1) {
			return '';
		}
		return name.slice(0, indexOf);
	}

	public getPlayerAliasLastName(name: string): string {
		const indexOf = name.indexOf(' ');
		if (indexOf === -1) {
			return name;
		}

		return name.slice(name.indexOf(' '));
	}
}
