import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BehaviorSubject, combineLatest, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'nx-bundesliga-bundesmaster-form-errors-display',
	standalone: true,
	imports: [CommonModule, MatFormFieldModule, FormsModule, ReactiveFormsModule],
	templateUrl: './bundesmaster-ui-form-errors-display.component.html',
	styleUrl: './bundesmaster-ui-form-errors-display.component.scss'
})
export class BundesmasterUiFormErrorsDisplayComponent implements OnInit, OnDestroy {
	@Input() control: AbstractControl | undefined;

	errors$: BehaviorSubject<ValidationErrors> = new BehaviorSubject<ValidationErrors>({});

	destroy$: Subject<boolean> = new Subject<boolean>();

	ngOnInit(): void {
		if (!this.control) return;

		if (this.control.value) {
			this.control.markAsTouched();
			this.control.updateValueAndValidity();
			this.updateErrorMessages();
		}

		combineLatest([this.control.statusChanges, this.control.valueChanges])
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.updateErrorMessages());
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	private updateErrorMessages(): void {
		this.errors$.next(this.control.errors ?? {});
	}
}
